//permissionsMixin.js

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      loadPermissions: 'GET_USER_PERMISSIONS',
    }),

    async checkIfScreenAllowed() {
      if (!this.userPermissionsLoaded) {
        await this.loadPermissions(this.me.id);
      }
      return this.isScreenAllowed(this.formName);
    },

    isBtnAllowed(buttonName) {
      return this.isAllowed(this.formName, 'Button', buttonName);
    },
  },

  computed: {
    ...mapState({
      me: (state) => state.Login.me,
    }),

    ...mapGetters(['userPermissionsLoaded', 'isAllowed', 'isScreenAllowed']),

    saveBtnDisabled() {
      return this.errors.items.length > 0
    }
  }
}