<!--
File: RegionEditForm.vue
Description: form for adding/editing a single region.
-->
<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon">
      </md-card-header>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="[{ 'md-valid': !errors.has('region_key') }, { 'md-error': errors.has('region_key') }]">
            <label for="region_key">{{ $t('road_network.region_key') }}</label>
            <md-input id="region_key" v-model="region_key" type="text" data-vv-name="region_key" required
              v-validate="modelValidations.region_key" />
          </md-field>

          <md-field
            :class="[{ 'md-valid': !errors.has('region_description') }, { 'md-error': errors.has('region_description') }]">
            <label for="region_description">{{ $t('stdCols.name_en') }}</label>
            <md-input id="region_description" v-model="region_description" type="text" data-vv-name="region_description"
              required v-validate="modelValidations.region_description" />
          </md-field>
          <md-field
            :class="[{ 'md-valid': !errors.has('region_description_en') }, { 'md-error': errors.has('region_description_en') }]">
            <label for="region_description_en">{{ $t('stdCols.name_ru') }}</label>
            <md-input id="region_description_en" v-model="region_description_en" type="text"
              data-vv-name="region_description_en" required v-validate="modelValidations.region_description_en" />
          </md-field>

          <md-field :class="[{ 'md-valid': !errors.has('regional_dep') }, { 'md-error': errors.has('regional_dep') }]">
            <label for="regional_dep">{{ $t('road_network.regional_office') + ' (RU)' }}</label>
            <md-input id="regional_dep" v-model="regional_dep" type="text" data-vv-name="regional_dep" required
              v-validate="modelValidations.regional_dep" />
          </md-field>
          <md-field
            :class="[{ 'md-valid': !errors.has('regional_dep_en') }, { 'md-error': errors.has('regional_dep_en') }]">
            <label for="regional_dep_en">{{ $t('road_network.regional_office') + ' (EN)' }}</label>
            <md-input if="regional_dep_en" v-model="regional_dep_en" type="text" data-vv-name="regional_dep_en" required
              v-validate="modelValidations.regional_dep_en" />
          </md-field>
          <md-field style="width: 50px; top: -7px">
            <md-switch v-model="active" id="active">{{ $t('road_network.active') }}</md-switch>
          </md-field>
        </div>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button v-if="isBtnAllowed('SaveButton')" class="md-success" native-type="submit"
              @click.native.prevent="validate" :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.cancel') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { mapActions } from 'vuex'
  import { onClose } from '@/mixins/onCloseMixin'
  import messages from '@/mixins/messagesMixin'
  import permissions from "@/mixins/permissionsMixin"

  export default {
    name: 'region-edit-form',
    mixins: [messages, permissions],

    data() {
      return {
        formName: 'RegionEditForm',

        region_id: null,
        region_key: null,
        region_description: null,
        region_description_en: null,
        regional_dep_en: null,
        regional_dep: null,
        active: null,

        modelValidations: {
          region_key: { required: true, min: 3 },
          region_description: { required: true, min: 3 },
          region_description_en: { required: true, min: 3 },
          regional_dep_en: { required: true, },
          regional_dep: { required: true, min: 3 },
        }
      }
    },

    props: {
      oper: String
    },

    async mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onClose()
        return
      };

      const { rid = null } = this.$route.params
      this.region_id = rid
      if (this.oper === 'upd' && rid) {
        this.loadRegion(rid).then((res) => {
          const theRegion = Array.isArray(res) ? res[0] : res
          this.region_key = theRegion.region_key
          this.region_description = theRegion.region_description
          this.region_description_en = theRegion.region_description_en
          this.regional_dep = theRegion.regional_dep
          this.regional_dep_en = theRegion.regional_dep_en
          this.active = theRegion.active
        })
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadRegion: 'LOAD_RDB_REGION_BY_ID',
        addRegion: 'ADD_RDB_REGION',
        editRegion: 'UPD_RDB_REGION'
      }),

      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theRegion = {
          fk_rn: 1,
          region_key: this.region_key,
          region_description: this.region_description,
          region_description_en: this.region_description_en,
          regional_dep: this.regional_dep,
          regional_dep_en: this.regional_dep_en,
          active: this.active
        }

        let errDesc = ''
        try {
          this.oper === 'add' ? await this.addRegion(theRegion) : await this.editRegion({ id: this.region_id, theRegion })
        } catch (err) {
          errDesc = err.message
        }
        this.savedMessage(errDesc, this.$t('road_network.region'), this.region_description, this.region_description_en)

        this.$nextTick(() => this.$validator.reset())
        this.onClose()
      }
    },

    computed: {
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>